.nav_container {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: 'Courier New', Courier, monospace;
}

html {
    scroll-behavior: smooth;
}

.nav_home_link {
    color: white;
    width: fit-content;
    text-decoration: none;
    padding-left: 10px;
}

.nav_portfolio_link {
    color: white;
    width: fit-content;
    text-decoration: none;
    padding-left: 10px;
}

.nav_bio_link {
    color: white;
    width: fit-content;
    text-decoration: none;
    padding-left: 10px;
}

.nav_contact_link {
    color: white;
    width: fit-content;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
}
